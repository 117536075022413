/* eslint-disable react/prop-types */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';

// <InlineLabel label='Top 10 on Hacker News' icon='🏆' color='#FE5900'/>

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const lastBlogPost = data.allMdx.edges[0].node;
  const title = lastBlogPost.frontmatter.title || lastBlogPost.fields.slug;
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Hassen Chaieb" />
      <Bio />
      <h3 style={{
        fontFamily: 'Montserrat, sans-serif',
        marginBottom: 0,
      }}
      >
          last post
      </h3>
      <article key={lastBlogPost.fields.slug}>
        <h4
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginTop: 12,
            marginBottom: 0,
          }}
        >
          <Link style={{ boxShadow: 'none' }} to={lastBlogPost.fields.slug}>
            {title}
          </Link>
        </h4>
        <small>{lastBlogPost.frontmatter.date}</small>
      </article>
      <h3 style={{
        fontFamily: 'Montserrat, sans-serif',
        marginBottom: 0,
      }}
      >
        popular posts
      </h3>
      <article>
        <h4
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginTop: 12,
            marginBottom: 0,
          }}
        >
          <Link style={{ boxShadow: 'none' }} to={'data-challenges-history'}>
            {'A brief history of enterprise data challenges'}
          </Link>
        </h4>
        <small>{'November 16, 2020'}</small>
      </article>
      <article>
        <h4
          style={{
            fontFamily: 'Montserrat, sans-serif',
            marginTop: 12,
            marginBottom: 0,
          }}
        >
          <Link style={{ boxShadow: 'none' }} to={'aws-good-parts'}>
            {'The good parts of AWS - A visual summary'}
          </Link>
        </h4>
        <small>{'January 18, 2020'}</small>
      </article>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 1, sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
            node {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
    }
  }
`;
